/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

const TermsList = styled.ol`
  counter-reset: lvl_1;
  line-height: 150%;
  list-style: none;
  font-size: 1.6rem;
  margin: 0 0 7rem 4rem;
  max-width: 975px;
  padding: 0;

  > li {
    counter-increment: lvl_1;

    h3 {
      position: relative;

      &::before {
        content: counter(lvl_1) ".";
        left: -25px;
        position: absolute;
      }
    }

    p {
      margin-bottom: 1rem;
    }

    > ol {
      counter-reset: lvl_2;
      list-style: none;
      padding: 0;

      @media only screen and (max-width: 480px) {
        margin-left: -38px;
      }

      > li {
        padding: 0 0 1rem 4rem;
        position: relative;

        ol {
          list-style: lower-latin;
          margin: 2rem 0 2rem 3rem;
          padding: 0;
        }

        &::before {
          counter-increment: lvl_2;
          content: counter(lvl_1) "." counter(lvl_2);
          font-weight: 600;
          left: 0;
          position: absolute;
          text-align: right;
          top: 0;
        }
      }
    }
  }

  h3 {
    color: inherit;
    text-transform: uppercase;
  }

  ul {
    margin: 2rem 0 2rem 3rem;
    padding: 0;

    li {
      margin: 0 0 1rem;
    }
  }
`;

const PrivacyPolicyPage = () => (
  <>
    <Helmet htmlAttributes={{
      lang: 'pl',
    }}
    >
      <title>Polityka prywatności - Monety Olsztyn</title>
      <meta name="description" content="Skup starych monet, banknotów, starych dokumentów, pocztówki z Olsztyna" />
      <meta name="robots" content="noindex, follow" />
      <link rel="canonical" href="https://monety.olsztyn.pl/polityka-prywatnosci/" />
    </Helmet>

    <h1 style={{ marginBottom: '8px' }}>
      Polityka prywatności
    </h1>

    <TermsList>
      <li>
        <h3>Podstawowe pojęcia</h3>

        <ul>
          <li><strong>Administrator</strong> – Monety Olsztyn;</li>
          <li><strong>Użytkownik</strong> – każda osoba fizyczna, której dane osobowe są przetwarzane przez Administratora;</li>
          <li><strong>Dane osobowe</strong> – wszystkie informacje o osobie fizycznej zidentyfikowanej lub możliwej do zidentyfikowania poprzez jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość, a także IP urządzenia, dane o lokalizacji, identyfikator internetowy oraz informacje gromadzone za pośrednictwem plików cookies oraz innej podobnej technologii;</li>
          <li><strong>RODO</strong> – Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE;</li>
          <li><strong>Serwis</strong> – rozwiązanie informatyczne znajdujące się pod adresem internetowym <a href="https://monety.olsztyn.pl" title="Skup starych monet i banknotów w Olsztynie">monety.olsztyn.pl</a> wraz z subdomenami i ewentualnie innymi adresami internetowymi, na które składa się m.in. kompleks usług świadczonych drogą elektroniczną na rzecz Użytkowników.</li>
          <li><strong>Przetwarzanie danych osobowych</strong> – jakiekolwiek operacje wykonywane na danych osobowych, takie jak zbieranie, utrwalanie, przechowywanie, opracowywanie, zmienianie, udostępnianie i usuwanie, a zwłaszcza te, które wykonuje się w systemach informatycznych;</li>
          <li><strong>Naruszenie ochrony danych osobowych</strong> – naruszenie bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z prawem zniszczenia, utraty, zmodyfikowania, nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych przesyłanych, przechowywanych lub w inny sposób przetwarzanych;</li>
        </ul>
      </li>

      <li>
        <h3>Pliki cookies oraz podobna technologia</h3>

        <ol>
          <li><p>Serwis korzysta z plików cookies.</p></li>
          <li><p>Pliki cookies (tzw. <em>„ciasteczka”</em>) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p></li>
          <li><p>Pliki cookies wykorzystywane są m.in. w celach:</p>
            <ol>
              <li>tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych;</li>
              <li>utrzymanie sesji użytkownika Serwisu dzięki której Użytkownik nie musi na każdej podstronie serwisu ponownie wpisywać loginu i hasła;</li>
              <li>określania profilu użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google.</li>
            </ol>
          </li>
          <li><p>W ramach Serwisu mogą być stosowane zasadniczo dwa rodzaje plików cookies: <em>„sesyjne”</em> (session cookies) oraz <em>„stałe”</em> (persistent cookies). Cookies <em>„sesyjne”</em> są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). <em>„Stałe”</em> pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</p></li>
          <li><p>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p></li>
          <li><p>Administrator korzysta z usług podmiotów trzecich, których lista stale zmienia się, a które mogą wykorzystywać pliki cookies m.in. w następujących celach:</p>
            <ul>
              <li>monitorowanie ruchu na stronach internetowych Administratora;</li>
              <li>zbieranie anonimowych, zbiorczych statystyk, które pozwalają zrozumieć, w jaki sposób Użytkownicy korzystają ze strony internetowej Administratora,</li>
              <li>kontrolowanie jak często pokazywana jest użytkownikom wybrana treść;</li>
              <li>kontrolowanie jak często użytkownicy wybierają daną usługę;</li>
              <li>badanie zapisów na newslettery;</li>
              <li>wykorzystanie narzędzia do komunikacji;</li>
              <li>integracja z portalem społecznościom;</li>
            </ul>
          </li>
          <li><p>Użytkownik może zarządzać plikami cookies wykorzystywanymi przez Administratora lub przez jakichkolwiek innych zewnętrznych dostawców, zmieniając ustawienia swojej przeglądarki internetowej. Dalsze informacje na ten temat znajdują się w dokumencie <Link to="/cookies/">Polityka plików cookies</Link> dostępnym na stronie internetowej Serwisu.</p></li>
          <li><p>Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.</p></li>
        </ol>
      </li>

      <li>
        <h3>Logi serwera</h3>

        <ol>
          <li><p>Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest strona. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera.</p></li>
          <li><p>Logi obejmują m.in. adres IP Użytkownika, datę i czas serwera, informacje o przeglądarce internetowej i systemie operacyjnym z jakiego korzysta. Logi zapisywane i przechowywane są na serwerze.</p></li>
          <li><p>Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze strony i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika.</p></li>
          <li><p>Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.</p></li>
        </ol>
      </li>

      <li>
        <h3>Postanowienia końcowe</h3>
        <p style={{ margin: '0' }}>Polityka prywatności jest na bieżąco weryfikowana i w razie potrzeby aktualizowana.</p>
        <p>Polityka prywatności obowiązuje od dnia 01.01.2020 r.</p>
      </li>
    </TermsList>

  </>
);

export default PrivacyPolicyPage;
